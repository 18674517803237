<template>
  <div class="page-offline-upload">
    <div class="banner">
      <img src="../assets/images/caishuipinggu.jpg" alt="" width="100%">
    </div>
    <div class="offline">
      <div class="down">
        <span>检测数据采集，请下载固定的模拟数据文件并上传检测：</span>
        <el-button type="primary" @click="download">下载模板</el-button>
      </div>
      <div class="file-area">
        <el-upload :auto-upload="false" class="upload-demo" drag :show-file-list="false" ref="upload"
                   :action="uploadUrl" :on-change="onFileChange" accept=".xls, .xlsx" :on-success="onSuccess">
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
          <div class="el-upload__tip" slot="tip">只能上传xls/xlsx文件，且不超过2M</div>
        </el-upload>
        <div class="el-upload-list">
          <div class="fileName">{{fileName}}</div>
          <div class="upload-btn"><el-button type="primary" :disabled="!fileName" @click="submitUpload">上传</el-button></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  export default {
    name: 'offlineUpload',
    data () {
      return {
        fileName: '',
        loading: Object
      }
    },
    computed: {
      uploadUrl () {
        //const id = this.userInfo ? this.userInfo.id : '14'
        const id = 14;
        return `/v1/api/xsb/tax/import/${id}`
      },
      ...mapState({
        userInfo: (state) => state.userInfo
      })
    },
    methods: {
      onFileChange (file, fileList) {
        this.fileName = file ? file.name : ''
      },
      submitUpload () {
        if (this.userInfo) {
          this.loading = this.$loading({
            lock: true,
            text: '数据分析中,请稍等...',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
          })
          setTimeout(() => {
            this.$refs.upload.submit()
          }, 3000)
        } else {
          this.$store.commit('SET_SHOW_LOGIN', true)
        }
      },
      onSuccess (response, file) {
        if (response.code === 0) {
          const downUrl = '/v1/download/' + response.data.reportRecordUrl
          // this.downLoadUrl(downUrl)
          window.open(downUrl)
          this.$refs.upload.clearFiles()
          this.fileName = ''
        } else {
          this.$message.error(response.msg)
        }
        this.loading.close()
      },
      download () {
        window.location.href = '/template.xlsx'
      }
    }
  }
</script>

<style scoped lang="scss">
  .page-offline-upload{
    background-color: #fff;
    .offline{
      width: 1200px;
      margin: 0 auto;
      padding: 30px 0;
      .file-area{
        padding: 30px 0;
        .el-upload-list{
          margin: 30px auto;
          width: 650px;
          .fileName{
            height: 40px;
            line-height: 40px;
            border: 1px solid #ccc;
            text-align: left;
            display: inline-block;
            width: 500px;
            vertical-align: middle;
            border-radius: 4px;
            padding: 0 15px;
          }
          .upload-btn{
            width: 100px;
            display: inline-block;
            margin-left: 15px;
            .el-button{
              width: 100%;
            }
          }
        }
      }
    }
  }
</style>
